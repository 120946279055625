import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider, useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './content/Home/HowItWorks/style.scss';

import { CssBaseline, Zoom, createTheme } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import Axios from 'axios';
import { API_KEY, API_URL, STRIPE_KEY, WEB_URL } from './config';
import packageInfo from '../package.json';
import {
  authControllerLogout,
  categoryControllerFindAll,
  deviceControllerCreate,
  Language,
  Os,
  userControllerFindMe,
  UserType
} from './utils/APIs';
import store, { RootState } from './store';
import { logout, setAccessToken, setUser } from './store/AuthReducer';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setDeviceId, setLoggedIn } from './store/ConfigsReducer';
import { useDispatch } from 'react-redux';
import { setCategories } from './store/CategoriesReducer';
import { loadStripe } from '@stripe/stripe-js';
// import { requestForToken } from './firebase';

import FloatingWhatsApp from 'react-floating-whatsapp';

import icon from './Assets/Images/icon-512x512.png';
import { useTranslation } from 'react-i18next';

Axios.defaults.baseURL = API_URL;
Axios.defaults.headers.common['X-APP-VERSION'] = packageInfo.version;
Axios.defaults.headers.common['X-API-KEY'] = API_KEY;
Axios.defaults.headers.common['X-TYPE'] = UserType.brand;

Axios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      if (store.getState().ConfigsReducer.deviceId)
        config.headers['X-DEVICE'] = store.getState().ConfigsReducer.deviceId;

      if (store.getState().AuthReducer.access_token)
        config.headers.Authorization = `Bearer ${
          store.getState().AuthReducer.access_token
        }`;
    }

    return {
      ...config
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use((response) => {
  if (response.headers['x-extended-access-token']) {
    store.dispatch(setAccessToken(response.headers['x-extended-access-token']));
    Axios.defaults.headers.common.Authorization = `Bearer ${response.headers['x-extended-access-token']}`;
  }

  return response;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;

    if (status === 401) {
      store.dispatch(logout());
      store.dispatch(setLoggedIn(false));
      window.location.href = WEB_URL;
    }

    return Promise.reject(error);
  }
);

const ltrTheme = createTheme({
  direction: 'ltr',
  colors: {
    gradients: {
      blue1: '',
      blue2: '',
      blue3: '',
      blue4: '',
      blue5: '',
      orange1: '',
      orange2: '',
      orange3: '',
      purple1: '',
      purple3: '',
      pink1: '',
      pink2: '',
      green1: '',
      green2: '',
      black1: '',
      black2: ''
    },
    shadows: {
      success: '',
      error: '',
      primary: '',
      warning: '',
      info: ''
    },
    alpha: {
      white: {
        5: '',
        10: '',
        30: '',
        50: '',
        70: '',
        100: ''
      },
      trueWhite: {
        5: '',
        10: '',
        30: '',
        50: '',
        70: '',
        100: ''
      },
      black: {
        5: '',
        10: '',
        30: '',
        50: '',
        70: '',
        100: ''
      }
    },
    secondary: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    primary: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    success: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    warning: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    error: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    info: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    lightPurple: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    white: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    }
  },
  general: {
    reactFrameworkColor: undefined,
    borderRadiusSm: '',
    borderRadius: '',
    borderRadiusLg: '',
    borderRadiusXl: ''
  },
  sidebar: {
    background: undefined,
    boxShadow: undefined,
    width: '',
    textColor: undefined,
    dividerBg: undefined,
    menuItemColor: undefined,
    menuItemColorActive: undefined,
    menuItemBg: undefined,
    menuItemBgActive: undefined,
    menuItemIconColor: undefined,
    menuItemIconColorActive: undefined,
    menuItemHeadingColor: undefined
  },
  header: {
    height: '',
    background: undefined,
    boxShadow: undefined,
    textColor: undefined
  }
});
const rtlTheme = createTheme({
  direction: 'rtl',
  colors: {
    gradients: {
      blue1: '',
      blue2: '',
      blue3: '',
      blue4: '',
      blue5: '',
      orange1: '',
      orange2: '',
      orange3: '',
      purple1: '',
      purple3: '',
      pink1: '',
      pink2: '',
      green1: '',
      green2: '',
      black1: '',
      black2: ''
    },
    shadows: {
      success: '',
      error: '',
      primary: '',
      warning: '',
      info: ''
    },
    alpha: {
      white: {
        5: '',
        10: '',
        30: '',
        50: '',
        70: '',
        100: ''
      },
      trueWhite: {
        5: '',
        10: '',
        30: '',
        50: '',
        70: '',
        100: ''
      },
      black: {
        5: '',
        10: '',
        30: '',
        50: '',
        70: '',
        100: ''
      }
    },
    secondary: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    primary: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    success: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    warning: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    error: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    info: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    lightPurple: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    },
    white: {
      lighter: '',
      light: '',
      main: '',
      dark: ''
    }
  },
  general: {
    reactFrameworkColor: undefined,
    borderRadiusSm: '',
    borderRadius: '',
    borderRadiusLg: '',
    borderRadiusXl: ''
  },
  sidebar: {
    background: undefined,
    boxShadow: undefined,
    width: '',
    textColor: undefined,
    dividerBg: undefined,
    menuItemColor: undefined,
    menuItemColorActive: undefined,
    menuItemBg: undefined,
    menuItemBgActive: undefined,
    menuItemIconColor: undefined,
    menuItemIconColorActive: undefined,
    menuItemHeadingColor: undefined
  },
  header: {
    height: '',
    background: undefined,
    boxShadow: undefined,
    textColor: undefined
  }
});

export const stripePromise = loadStripe(STRIPE_KEY);

function App() {
  const { t }: { t: any } = useTranslation();
  const content = useRoutes(router);
  const dispatch = useDispatch();

  // const { enqueueSnackbar } = useSnackbar();

  const {
    ConfigsReducer: { deviceId, loggedIn, language }
  } = useSelector((state: RootState) => state);

  const GetCurrentAccountAction = useCallback(async () => {
    userControllerFindMe()
      .then(({ data }) => {
        dispatch(setUser(data));
      })
      .catch((err) => {
        // enqueueSnackbar(err?.response?.data?.message, {
        //   variant: 'success',
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center'
        //   },
        //   TransitionComponent: Zoom
        // });
      })
      .finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn) GetCurrentAccountAction();
  }, [GetCurrentAccountAction]);

  useEffect(() => {
    if (!deviceId) {
      deviceControllerCreate({
        language: Language.en,
        os: Os.browser
      }).then(({ data }) => {
        dispatch(setDeviceId(data.id));
      });
    }
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await categoryControllerFindAll();
        const translatedCategories = res.data.map((category) => ({
          ...category,
          name: t(category.name)
        }));
        dispatch(setCategories(translatedCategories));
      } catch (err) {
        // Handle error, e.g., using a notification system
        // enqueueSnackbar(err?.response?.data?.message, {
        //   variant: 'error',
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center'
        //   },
        //   TransitionComponent: Zoom
        // });
      }
    };

    fetchCategories();
  }, [dispatch, t]);

  // const Notification = () => {
  //   requestForToken();
  // };

  // useEffect(() => {
  //   Notification();
  // }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {content}
          <FloatingWhatsApp
            phoneNumber="+962776219208"
            accountName="Elli Creators"
            chatMessage="Hello There, how can we help?"
            avatar={icon}
          />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
