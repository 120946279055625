import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account
const LoginBasic = Loader(lazy(() => import('src/content/pages/Auth/Login')));

const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register'))
);

const BrandInfo = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Components/BrandInfo'))
);

const CompleteRegistration = Loader(
  lazy(
    () =>
      import('src/content/pages/Auth/Register/Components/CompleteRegistration')
  )
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const accountRoutes = [
  {
    path: 'login',
    element: <LoginBasic />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },
  {
    path: 'register',
    element: <RegisterBasic />
  },
  {
    path: 'welcome',
    element: <BrandInfo />
  },
  {
    path: 'brand-info',
    element: <CompleteRegistration />
  }
];

export default accountRoutes;
