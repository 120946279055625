import { Navigate, RouteObject, useParams } from 'react-router';

import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import Status404 from 'src/content/pages/Status/Status404';

import dashboardsRoutes from './dashboards';
import accountRoutes from './account';

const TaskRedirect = () => {
  const { id } = useParams();
  window.location.href = `https://api.ellicreators.com/creator/task/${id}`;
  return null;
};

const router: RouteObject[] = [
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: '',
    element: <TopNavigationLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        children: dashboardsRoutes
      }
    ]
  },
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: 'task/:id',
    element: <TaskRedirect />
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default router;
