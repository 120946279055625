import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics'))
);

const GetStarted = Loader(
  lazy(() => import('src/content/dashboards/GetStarted'))
);

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

const EditTask = Loader(
  lazy(
    () => import('src/content/dashboards/Tasks/Components/EditTask/EditTask')
  )
);

const ViewTask = Loader(
  lazy(
    () => import('src/content/dashboards/Tasks/Components/ViewTask/ViewTask')
  )
);

const AddTask = Loader(
  lazy(() => import('src/content/dashboards/Tasks/Components/AddTask/AddTask'))
);

const MyProfile = Loader(
  lazy(() => import('src/content/dashboards/MyProfile'))
);

const EditProfile = Loader(
  lazy(() => import('src/content/dashboards/MyProfile/Components/EditProfile'))
);

const Creators = Loader(lazy(() => import('src/content/dashboards/Creators')));

const Videos = Loader(lazy(() => import('src/content/dashboards/Videos')));

const Delivery = Loader(lazy(() => import('src/content/dashboards/Delivery')));

const Brand = Loader(lazy(() => import('src/content/dashboards/Brand')));

const dashboardsRoutes = [
  {
    path: '',
    element: <Analytics />
  },
  {
    path: 'creators',
    element: <Creators />
  },
  {
    path: 'videos',
    element: <Videos />
  },
  {
    path: 'my-profile',
    element: <MyProfile />
  },
  {
    path: 'edit-profile',
    element: <EditProfile />
  },
  {
    path: 'delivery',
    element: <Delivery />
  },
  {
    path: 'brand',
    element: <Brand />
  },
  {
    path: 'tasks',
    children: [
      {
        path: '',
        element: <Tasks />
      },
      {
        path: 'add-task',
        element: <AddTask />
      },
      {
        path: 'view-task/:id',
        element: <ViewTask />
      },
      {
        path: 'edit-task/:id',
        element: <EditTask />
      }
    ]
  }
];

export default dashboardsRoutes;
