import type { ReactNode } from 'react';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  Collections,
  Dashboard,
  Group,
  LocalShipping,
  Start,
  Task,
  WorkOutline
} from '@mui/icons-material';
import i18n from 'src/i18n/i18n';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      // {
      //   name: 'Get started',
      //   icon: Start,
      //   link: '/dashboard/get-started'
      // },
      {
        name: i18n.t('Dashboard'),
        icon: Dashboard,
        link: '/dashboard/'
      },

      {
        name: i18n.t('Tasks'),
        icon: Task,
        link: '/dashboard/tasks'
      },
      {
        name: i18n.t('Creators'),
        icon: Group,
        link: '/dashboard/creators'
      },
      {
        name: i18n.t('Delivery'),
        icon: LocalShipping,
        link: '/dashboard/delivery'
      },
      {
        name: i18n.t('Videos'),
        icon: Collections,
        link: '/dashboard/videos'
      },
      // {
      //   name: 'Inspiration',
      //   icon: AccountTreeTwoToneIcon,
      //   link: '/dashboard/inspiration'
      // },
      {
        name: i18n.t('Brand'),
        icon: WorkOutline,
        link: '/dashboard/brand'
      }

      // {
      //   name: '',
      //   icon: MenuTwoToneIcon,
      //   link: '',
      //   items: [
      //     {
      //       name: 'My brands',
      //       link: '/'
      //     }
      //   ]
      // }
    ]
  }
];

export default menuItems;
