export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const API_KEY = 'RUxMSVNFUlZFUkFQSUtFWTIwMjM=';

export const DOMAIN =
  window.location.hostname === 'dash.ellicreators.com'
    ? 'ellicreators.com'
    : 'staging.ellicreators.com';

export const WEB_URL = `https://${DOMAIN}`;
export const API_URL = `https://api.${DOMAIN}`;

export const IMAGE_PUBLIC_SERVE = `${API_URL}/file/image`;
export const VIDEO_PUBLIC_SERVE = `${API_URL}/file/video`;
export const AUDIO_PUBLIC_SERVE = `${API_URL}/file/audio`;

export const STRIPE_KEY =
  window.location.hostname === 'dash.ellicreators.com'
    ? 'pk_live_51MyujqCiPjYIJLF6CLqe6UDCHnqfWdRCS96SbGgDfhRexFBuEJBs0yqQ20cbS05n6wuFrOiA5bvxwKEcrNbbdeTL00wcUMhGa7'
    : 'pk_test_51MyujqCiPjYIJLF6GjnuAcmNNPYQ9dFIVxeCeuMFjS2dsLuRNWdLN6OINBlBv7UbofiXWyJoAqL5RzJwKQnxtkS300aFrN3qxg';
